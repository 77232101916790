import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { changeLanguage as changeLang } from './i18n';

const Navbar = ({ isShrink }) => {
  const [shrink, setShrink] = useState(false);
  const navigate = useNavigate();
  const { t, i18n } = useTranslation();
  const [currentLanguage, setCurrentLanguage] = useState(i18n.language);
  const [showAlternateText, setShowAlternateText] = useState(false);
  const [isNavbarVisible, setIsNavbarVisible] = useState(false);

  const handleScroll = () => {
    if (window.location.pathname.includes('/view-pdf/')) {
      setShrink(true);
    } else {
      setShrink(window.scrollY > 40);
    }
  };

  useEffect(() => {
    handleScroll(); 
    window.addEventListener('scroll', handleScroll);
    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);

  const toggleNavbar = () => {
    setIsNavbarVisible(!isNavbarVisible);
  };

  const toggleLanguage = () => {
    const newLanguage = currentLanguage === 'fr' ? 'en' : 'fr';
    setCurrentLanguage(newLanguage);
    changeLang(newLanguage);
  };

  const NavbarContent = () => (
    <>
      <li className="lh-55px nav-item">
        <a href="/contact" className="btn login-btn ml-50" id='contactBTN'>
         <span>Contactez-moi</span> 
        </a>
      </li>
      <li className="lh-55px nav-item" style={{ display: 'flex', alignItems: 'center' }}>
        <a href="https://github.com/trsxvz" target="_blank" rel="noopener noreferrer" className="ml-3" style={{ display: 'flex', alignItems: 'center', position: 'relative' }}>
          <img 
            src="../img/icons/GitHub_Logo_White.png" 
            alt="GitHub" 
            width="100" 
            style={{ 
              transition: 'transform 0.3s ease', 
              cursor: 'pointer',
              filter: 'invert(17%) sepia(100%) saturate(5000%) hue-rotate(270deg) brightness(100%) contrast(100%)',
              position: 'relative',
              zIndex: '2'
            }}
            onMouseEnter={e => e.currentTarget.style.transform = 'scale(1.1)'}
            onMouseLeave={e => e.currentTarget.style.transform = 'scale(1)'}
          />
        </a>
      </li>
    </>
  );

  return (
    <nav className={`navbar navbar-expand-lg navbar-white fixed-top ${shrink ? 'shrink' : ''}`} id="banner">
      <div className="container">
        <a className="navbar-brand" href="/">
          <span><img draggable="false" src="../img/core-img/logoSBGST.png" alt="logo" width="80"/></span> 
        </a>

        <button className="navbar-toggler d-lg-none" type="button" onClick={toggleNavbar}>
          <span className="navbar-toggler-icon"></span>
        </button>

        {isNavbarVisible && (
          <div className={`navbar-collapse d-lg-none ${isNavbarVisible ? 'navbar-mobile-open' : ''}`}>
            <ul className="navbar-nav ml-auto" style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', width: '100%' }}>
              <NavbarContent />
            </ul>
          </div>
        )}

        <div className="collapse navbar-collapse d-none d-lg-block">
          <ul className="navbar-nav ml-auto">
            <NavbarContent />
          </ul>
        </div>
      </div>
    </nav>
  );
};

export default Navbar;